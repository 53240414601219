<template>
  <component
    :is="icon"
    width="1em"
    height="1em"
    stroke="currentColor"
    :viewBox="'0 0 ' + (size) + ' ' + (size)"
  ></component>
</template>

<script>
export default {
  name: "icon",
  props: {
    src: {
      type: String,
      require: true,
    },
    size: {
      type: Number,
      default: 0,
    },
  },

  data: function () {
    return {
      icon: null,
    };
  },

  watch: {
    async src(val) {
      if (val) {
        try {
          this.icon = await import(`@/assets/icons/${this.src}.svg`);
        } catch (error) {
          this.icon = null;
        }
      }
    },
  },

  beforeCreate() {},

  async mounted() {
    try {
      this.icon = await import(`@/assets/icons/${this.src}.svg`);
    } catch (error) {
      this.icon = null;
    }
  },
};
</script>
