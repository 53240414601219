<template>
  <div class="list-container">
    <Header></Header>
    <HeaderBottom></HeaderBottom>
    <b-container class="list-container-content">
      <div class="list-container-content-main">
        <div class="action">
          <Icon src="ic_arrow_left" :size="26" />
          <p>Kembali</p>
        </div>

        <!-- Container -->
        <div class="container-content">
          <h1>Daftar Manajer Investasi</h1>

          <div class="content-action">
            <div>
              <input
                type="text"
                placeholder="Pencarian Manajer Investasi"
                v-model="searchNav"
              />
              <Icon src="ic-search" :size="26" />
              <!-- <Search /> -->
            </div>

            <button class="primary" @click="bandingkanAction">
              Bandingkan
            </button>
          </div>

          <!-- Container Table -->
          <div class="container-table">
            <b-skeleton-table v-if="statusRequest === 'loading'" />
            <div v-else-if="statusRequest === 'error'" class="error-container">
              <h1>Gagal memuat data</h1>
            </div>
            <table v-else>
              <thead>
                <tr class="bg-[#E60012!important]">
                  <th rowspan="2">No</th>
                  <th rowspan="2">Manajer Investasi</th>
                  <th rowspan="2">Periode</th>
                  <th colspan="2">AUM Terakhir</th>
                  <th colspan="2">Unit Terakhir</th>
                  <th rowspan="2">Pilih</th>
                </tr>
                <tr>
                  <th>IDR</th>
                  <th>USD</th>
                  <th>IDR</th>
                  <th>USD</th>
                </tr>
              </thead>
              <tbody v-if="filterMiWithRelatedData">
                <tr
                  :key="item.im.id"
                  v-for="(item, index) in filterMiWithRelatedData"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.im.im_name || "Tidak diketahui" }}</td>
                  <td>
                    {{ item.data.date.from.format("D MMM YYYY") }}
                    -
                    {{ item.data.date.after.format("D MMM YYYY") }}
                  </td>
                  <td>
                    {{ numberFormat(item.data.navAum.idr) || "-" }}
                  </td>
                  <td>
                    {{ item.data.navAum.usd || "-" }}
                  </td>
                  <td>
                    {{ numberFormat(item.data.navUnit.idr) || "-" }}
                  </td>
                  <td>
                    {{ item.data.navUnit.usd || "-" }}
                  </td>

                  <td>
                    <input
                      type="checkbox"
                      class="form-group"
                      @click="checkboxAction"
                      :value="item.im.id"
                      :checked="listNavChecked.includes(parseInt(item.im.id))"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Load More Button -->
        <button
          :disabled="statusRequest === 'iddle' ? false : true"
          @click="showMore = !showMore"
          class="primary"
        >
          {{ showMore ? "Lihat Sebagian" : "Lihat Semua" }}
        </button>
      </div>
    </b-container>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/partials/header/Header.vue";
import HeaderBottom from "@/components/partials/header/HeaderBottom.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import Icon from "@/components/partials/common/icon.vue";
import Axios from "axios";
import moment from "moment";

const InstanceAxios = Axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
});

export default {
  name: "ListManajerInvestasi",
  metaInfo: {
    title: "List Manajer Investasi | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottom,
    Footer,
    // Search,
    Icon,
  },
  data() {
    return {
      listManagerInvestasi: null,
      listNav: null,
      statusRequest: "iddle", // iddle, loading, error
      showMore: false,
      listNavChecked: [],
      searchNav: "",
    };
  },
  methods: {
    checkboxAction(e) {
      if (this.listNavChecked.includes(parseInt(e.target.value))) {
        this.listNavChecked = this.listNavChecked.filter(
          (candidate) => candidate !== parseInt(e.target.value)
        );
      } else {
        if (this.listNavChecked.length >= 3) return e.preventDefault();
        this.listNavChecked.push(parseInt(e.target.value));
      }
    },
    bandingkanAction() {
      if (this.listNavChecked.length < 3) return alert("HARAP MEMILIH 3");
      this.$router.push(
        `${this.$route.path}/compare?product1=${this.listNavChecked[0]}&product2=${this.listNavChecked[1]}&product3=${this.listNavChecked[2]}`
      );
    },
    numberFormat(value) {
      const formatter = new Intl.NumberFormat('id', {
          // style: 'currency',
      });
      return value ? formatter.format(value) : '-';
    }
  },
  mounted() {
    async function requestData() {
      try {
        this.statusRequest = "loading";
        //const request = await InstanceAxios("api/mi?type=1");
        const request = await InstanceAxios("api/core/investment-manager");
        const resultData = request?.data?.data;

        // Request 2
        const request2 = await InstanceAxios("api/core/nav-history?api=1");
        //const request2 = await InstanceAxios("api/3041");
        const resultData2 = request2?.data?.dataNav;

        // Cek struktur
        // Cek struktur dari respon
        if (
          !resultData ||
          !Array.isArray(resultData) ||
          !resultData2 ||
          !Array.isArray(resultData2)
        ) {
          throw new Error("Struktur respond yang diterima tidak sesuai");
        }
        this.listManagerInvestasi = resultData;
        this.listNav = resultData2;
        this.statusRequest = "iddle";
      } catch (err) {
        this.statusRequest = "error";
        console.log("\n\n================================");
        console.log(err);
        alert("ERROR");
      }
    }

    requestData.call(this);
  },

  computed: {
    miWithRelatedData() {
      if (!this.listNav || !this.listManagerInvestasi) return null;

      return this.listManagerInvestasi.map((candidate) => {
        return {
          im: { ...candidate },
          data: this.listNav
            .filter((candidateItem) => candidateItem.im_id === candidate.id)
            .reduce(
              (prev, current) => {
                const newPrev = { ...prev };

                newPrev["navAum"][current.fund_ccy === "IDR" ? "idr" : "usd"] =
                  parseFloat(
                    newPrev["navAum"][
                      current.fund_ccy === "IDR" ? "idr" : "usd"
                    ]
                  ) + parseFloat(current.navAum);

                newPrev["navUnit"][current.fund_ccy === "IDR" ? "idr" : "usd"] =
                  parseFloat(
                    newPrev["navUnit"][
                      current.fund_ccy === "IDR" ? "idr" : "usd"
                    ]
                  ) + parseFloat(current.navUnit);

                const before = moment(current.nav_date, "YYYY-MM-DD");
                if (before.isBefore(newPrev.date.from)) {
                  newPrev.date.from = before;
                }

                const after = moment(current.nav_date, "YYYY-MM-DD");
                if (after.isAfter(newPrev.date.after)) {
                  newPrev.date.from = after;
                }

                // if (
                //   parseFloat(current.navAum) > parseFloat(prev.navAum.value)
                // ) {
                //   newPrev["navAum"]["value"] = parseFloat(current.navAum);
                //   newPrev["navAum"]["type"] = current.fund_ccy;
                // }

                // if (
                //   parseFloat(current.navUnit) > parseFloat(prev.navUnit.value)
                // ) {
                //   newPrev["navUnit"]["value"] = parseFloat(current.navUnit);
                //   newPrev["navUnit"]["type"] = current.fund_ccy;
                //   newPrev["date"] = new Date(current.nav_date).getTime();
                // }

                return newPrev;
              },
              {
                navAum: {
                  idr: 0,
                  usd: 0,
                },
                navUnit: {
                  idr: 0,
                  usd: 0,
                },
                date: {
                  from: moment(),
                  after: moment(),
                },
              }
            ),
        };
      });
    },
    filterMiWithRelatedData() {
      if (!this.miWithRelatedData) return null;
      const result = this.miWithRelatedData.filter((candidate) => {
        if (this.searchNav === "") return true;
        return candidate?.im?.im_name.search(this.searchNav) === -1
          ? false
          : true;
      });

      return this.showMore ? result : result.slice(0, 3);
    },
  },
};
</script>

<style scoped lang="scss">
.list-container {
  width: 100%;
  background-color: #f3f4f6;

  @mixin button() {
    padding: 12px 16px;
    background-color: #0b318f;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: white;
    border: 0;
  }

  .list-container-content {
    padding: $marginTopForContent 16px 20px;

    @media screen and (min-width: $lg) {
      & {
        padding: $marginTopForContent 16px 50px;
      }
    }

    .list-container-content-main {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .action {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #908f94;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;

        p {
          margin-left: 7px;
          line-height: 150%;
          margin: 0;
        }

        svg {
          font-size: 16px;
        }
      }

      .container-content {
        box-shadow: 0px 2px 4px rgba(107, 114, 128, 0.06),
          0px 4px 6px rgba(107, 114, 128, 0.15);
        background-color: white;
        padding: 20px;
        gap: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media screen and (min-width: $lg) {
          & {
            padding: 40px;
            gap: 40px;
          }
        }

        h1 {
          font-weight: bold;
          font-size: 22px;
          line-height: 150%;
          color: black;
          text-align: center;

          @media screen and (min-width: $md) {
            & {
              font-size: 24px;
            }
          }

          @media screen and (min-width: $lg) {
            & {
              font-size: 26px;
            }
          }

          @media screen and (min-width: $xl) {
            & {
              font-size: 28px;
            }
          }
        }

        .content-action {
          display: flex;
          overflow: hidden;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 10px;
          gap: 15px;
          flex-direction: column;

          @media screen and (min-width: $md) {
            & {
              flex-direction: row;
              gap: 10px;

              div,
              div input,
              button {
                width: initial !important;
              }
            }
          }

          div {
            border-radius: 3px;
            background-color: #f5f5f5;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: initial;
            gap: 0 10px;
            width: 100%;

            input {
              font-weight: normal;
              font-size: 16px;
              line-height: 150%;
              color: black;
              background-color: transparent;
              border: 0;
              padding: 0;
              width: 100%;
            }
          }

          button {
            @include button();
            width: 100%;
          }
        }

        .container-table {
          width: 100%;
          overflow: auto;

          .error-container {
            width: 100%;
            height: 100%;
            padding: 20px;

            h1 {
              font-size: 24px;
            }
          }

          table {
            width: 100%;
            box-shadow: none;
            table-layout: auto;

            thead tr {
              background-color: #e60012;
              th {
                font-weight: bold;
                font-size: 16px;
                line-height: 150%;
                color: white;
                padding: 20px 24px;
              }
            }

            tbody td {
              text-align: center;
              padding: 20px 24px;
              font-size: 14px;
              line-height: 20px;
              font-weight: normal;
              color: black;
            }

            tbody td:nth-child(2) {
              text-align: start;
            }
          }
        }
      }

      button.primary {
        @include button();
        width: 100%;

        @media screen and (min-width: $md) {
          & {
            width: initial;
          }
        }
      }
    }
  }
}
</style>
